"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.weekOpts = exports.default = exports.Header = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _RoundButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/RoundButton"));
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _Label = require("@oberoninternal/travelbase-ds/components/primitive/Label");
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
var _addWeeks = _interopRequireDefault(require("date-fns/addWeeks"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _isSameMonth = _interopRequireDefault(require("date-fns/isSameMonth"));
var _isSameWeek = _interopRequireDefault(require("date-fns/isSameWeek"));
var _isValid = _interopRequireDefault(require("date-fns/isValid"));
var _formik = require("formik");
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _activitySearchParams = _interopRequireDefault(require("../constants/activitySearchParams"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _useDatepicker = _interopRequireDefault(require("../hooks/useDatepicker"));
var _useQueryParams = _interopRequireDefault(require("../hooks/useQueryParams"));
var _isDate = _interopRequireDefault(require("../utils/isDate"));
var _localeHelpers = require("../utils/localeHelpers");
var _parseDate = _interopRequireDefault(require("../utils/parseDate"));
var _trip = require("../utils/trip");
var _Datepicker = require("./designsystem/Datepicker");
var _Context = _interopRequireDefault(require("./designsystem/datepicker/Context"));
var _DatepickerModal = _interopRequireDefault(require("./designsystem/datepicker/DatepickerModal"));
var _ViewToggle = require("./designsystem/ViewToggle");
var _Stack = _interopRequireDefault(require("./Stack"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
/* eslint-disable no-lone-blocks */
const weekOpts = exports.weekOpts = {
  weekStartsOn: 1
};
const isOrBeforeToday = (date, excludeToday = false) => {
  const today = new Date();
  const sameDay = (0, _isSameDay.default)(date, today);
  const beforeToday = (0, _isBefore.default)(date, today);
  if (excludeToday) {
    return !sameDay && beforeToday;
  }
  return sameDay || beforeToday;
};
const AgendaHeader = ({
  onViewChange,
  resetDate
}) => {
  const [{
    value: startDate
  }] = (0, _formik.useField)('startDate');
  const [{
    value: endDate
  }] = (0, _formik.useField)('endDate');
  const [{
    value: view
  }] = (0, _formik.useField)('view');
  const [, setParams] = (0, _useQueryParams.default)(_activitySearchParams.default);
  const datepicker = (0, _useDatepicker.default)({
    focus: 'start',
    autoHide: false,
    optionalSingleDate: true,
    period: {
      start: new Date(startDate),
      end: new Date(endDate)
    }
  }, false);
  const [state, {
    sesame,
    set
  }] = datepicker;
  const resetWeek = (0, _react.useCallback)(() => {
    onViewChange('week');
  }, [onViewChange]);
  const router = (0, _router.useRouter)();
  const dateLocale = (0, _localeHelpers.getDateFnsLocale)(router.locale);
  const computeDayProps = (0, _react.useMemo)(() => (0, _Datepicker.createDayModifier)(state), [state]);
  const onPeriodClick = (0, _react.useCallback)((prev = false) => {
    const currentDate = new Date(startDate);
    const periodAdapter = addFn => addFn(currentDate, prev ? -1 : 1);
    switch (view) {
      case 'month':
        {
          let newDate = periodAdapter(_addMonths.default);
          if (isOrBeforeToday(newDate)) {
            if ((0, _isSameMonth.default)(newDate, new Date())) {
              newDate = new Date();
            } else {
              return;
            }
          }
          setParams({
            startDate: (0, _parseToDateString.default)(newDate),
            endDate: (0, _parseToDateString.default)(newDate)
          });
        }
        break;
      case 'week':
        {
          let newDate = periodAdapter(_addWeeks.default);
          if (isOrBeforeToday(newDate)) {
            if ((0, _isSameWeek.default)(newDate, new Date(), weekOpts)) {
              newDate = new Date();
            } else {
              return;
            }
          }
          setParams({
            startDate: (0, _parseToDateString.default)(newDate),
            endDate: (0, _parseToDateString.default)(newDate)
          });
        }
        break;
      case 'day':
        {
          const newDate = periodAdapter(_addDays.default);
          if (isOrBeforeToday(newDate, true)) {
            return;
          }
          setParams({
            startDate: (0, _parseToDateString.default)(newDate),
            endDate: (0, _parseToDateString.default)(newDate)
          });
        }
        break;
      default:
        break;
    }
  }, [startDate, setParams, view]);
  (0, _react.useEffect)(() => {
    set({
      period: (0, _trip.convertToPeriod)(startDate, endDate)
    });
  }, [startDate, endDate, set]);
  return __jsx(_Context.default, {
    value: {
      computeDayProps,
      datepicker,
      locale: dateLocale
    }
  }, __jsx(Header, null, __jsx(Navigation, null, __jsx("div", {
    className: "lt-m",
    style: {
      flex: 1
    }
  }, __jsx(MobileSearchBox, {
    onClick: () => sesame('open')
  }, __jsx(StyledText, null, (0, _isValid.default)(new Date(startDate)) && __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedDate, {
    value: startDate,
    day: "numeric",
    month: "short"
  })), (0, _isValid.default)(new Date(endDate)) && __jsx(_react.default.Fragment, null, ' — ', __jsx(_reactIntl.FormattedDate, {
    value: endDate,
    day: "numeric",
    month: "short"
  }))), startDate && endDate ? __jsx(StyledDatePickerEditText, null, __jsx(_reactIntl.FormattedMessage, {
    id: "/rVWo/",
    defaultMessage: [{
      "type": 0,
      "value": "Bewerken"
    }]
  })) : __jsx(StyledDatePickerText, null, __jsx(_reactIntl.FormattedMessage, {
    id: "ELaDA0",
    defaultMessage: [{
      "type": 0,
      "value": "Kies datum"
    }]
  }))), __jsx(_DatepickerModal.default, {
    hideHelper: false,
    helperChildren: __jsx(_react.default.Fragment, null, __jsx(_grid.Flex, {
      mt: 3,
      width: 1,
      flexDirection: "row",
      justifyContent: "space-between"
    }, __jsx(_Button.default, {
      onClick: () => {
        resetDate();
        set({
          period: null
        });
      },
      variant: "outline"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "DYi74L",
      defaultMessage: [{
        "type": 0,
        "value": "Reset datum"
      }]
    })), __jsx(_Button.default, {
      onClick: () => {
        sesame('close');
        if (!(0, _isDate.default)(state.period) && state.period?.start && state.period?.end) {
          setParams({
            startDate: (0, _parseToDateString.default)(new Date(state.period.start)),
            endDate: (0, _parseToDateString.default)(new Date(state.period.end)),
            view: null
          }, 'replaceIn');
        }
      }
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "+SNdt+",
      defaultMessage: [{
        "type": 0,
        "value": "Zoek activiteiten"
      }]
    }))))
  })), __jsx(StyledButton, {
    className: "gt-s",
    variant: "outline",
    onClick: () => resetDate()
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "q9hRlN",
    defaultMessage: [{
      "type": 0,
      "value": "Vandaag"
    }]
  })), __jsx("div", {
    className: "gt-m"
  }, __jsx(_Title.default, {
    style: {
      textTransform: view === 'month' ? 'capitalize' : undefined
    }
  }, view === 'day' && startDate === endDate ? __jsx(_reactIntl.FormattedDate, {
    value: new Date(startDate),
    format: "DAYMONTHYEAR"
  }) : __jsx(_reactIntl.FormattedDateTimeRange, {
    from: (0, _parseDate.default)(startDate),
    to: (0, _parseDate.default)(endDate),
    day: "numeric",
    month: "long",
    year: "numeric"
  }), view === 'month' && __jsx(_reactIntl.FormattedDate, {
    value: new Date(startDate),
    format: "MONTH"
  }))), __jsx(_Stack.default, {
    className: "gt-m",
    variant: "inline",
    spacing: 3
  }, __jsx(_RoundButton.default, {
    onClick: () => onPeriodClick(true),
    variant: "outline",
    size: "small"
  }, __jsx(_ArrowLeft.default, null)), __jsx(_RoundButton.default, {
    onClick: () => onPeriodClick(),
    variant: "outline",
    size: "small"
  }, __jsx(_ArrowRight.default, null)))), __jsx(_grid.Flex, {
    className: "lt-s",
    width: "100%",
    mt: 4,
    mb: 2,
    justifyContent: "left",
    flex: 1,
    flexDirection: ['row', 'column']
  }, __jsx(StyledLabel, null, "periode")), __jsx(_grid.Flex, {
    className: "lt-s",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    flex: 1,
    alignItems: "center"
  }, __jsx(_grid.Box, {
    mr: 3
  }, __jsx(_Button.default, {
    variant: "outline",
    onClick: () => {
      resetDate();
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "q9hRlN",
    defaultMessage: [{
      "type": 0,
      "value": "Vandaag"
    }]
  }))), __jsx(_grid.Box, null, __jsx(_Button.default, {
    variant: "outline",
    onClick: () => {
      resetWeek();
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "O+FM9S",
    defaultMessage: [{
      "type": 0,
      "value": "Deze week"
    }]
  }))))));
};
var _default = exports.default = AgendaHeader;
const MobileSearchBox = (0, _styledComponents.default)(_grid.Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
}).withConfig({
  displayName: "AgendaHeader__MobileSearchBox",
  componentId: "fcrvjk-0"
})(["width:100%;@media screen and (min-width:", "){width:'100%';max-width:36rem;}height:4.8rem;border:1px solid ", ";background:", ";box-shadow:0px 0.2rem 0.4rem 0px rgba(59,118,160,0.02),0px 0.4rem 1.2rem 0px rgba(59,118,160,0.04);border-radius:2.4rem;padding:1.6rem;cursor:pointer;@media screen and (min-width:", "){font-size:1.6rem;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.colors.neutral[0], ({
  theme
}) => theme.mediaQueries.xs);
const StyledLabel = (0, _styledComponents.default)(_Label.Label).withConfig({
  displayName: "AgendaHeader__StyledLabel",
  componentId: "fcrvjk-1"
})(["color:", ";"], ({
  theme
}) => theme.colors.neutral[50]);
const Navigation = _styledComponents.default.div.withConfig({
  displayName: "AgendaHeader__Navigation",
  componentId: "fcrvjk-2"
})(["display:flex;align-items:center;@media screen and (max-width:", "px){width:100%;}& > * + *{margin-left:", ";}"], ({
  theme
}) => theme.mediaQueriesValues.s - 1, ({
  theme
}) => theme.spacing['40_Standard']);
const Header = exports.Header = _styledComponents.default.header.withConfig({
  displayName: "AgendaHeader__Header",
  componentId: "fcrvjk-3"
})(["display:flex;grid-column:1 / -1;margin:4rem 0 2.4rem;justify-content:space-between;align-items:center;@media screen and (max-width:", "){flex-flow:column;align-items:flex-start;}@media screen and (min-width:", "){margin:4rem 0 0;height:4.8rem;", "{margin-left:auto;}}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.mediaQueries.s, _ViewToggle.StyledViewToggle);
const StyledText = _styledComponents.default.span.withConfig({
  displayName: "AgendaHeader__StyledText",
  componentId: "fcrvjk-4"
})(["font-weight:bold;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;"]);
const SpacingDatePicker = (0, _styledComponents.css)(["padding-left:", ";border-left:1px solid ", ";margin-left:5px;"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.colors.neutral['30']);
const StyledDatePickerEditText = _styledComponents.default.span.withConfig({
  displayName: "AgendaHeader__StyledDatePickerEditText",
  componentId: "fcrvjk-5"
})(["width:10rem;", ""], SpacingDatePicker);
const StyledDatePickerText = _styledComponents.default.span.withConfig({
  displayName: "AgendaHeader__StyledDatePickerText",
  componentId: "fcrvjk-6"
})(["width:12rem;", ""], SpacingDatePicker);
const StyledButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "AgendaHeader__StyledButton",
  componentId: "fcrvjk-7"
})(["margin-left:0;@media screen and (max-width:", ") and (min-width:", "){margin-left:2.4rem;}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.xs);