"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _createBorderCss = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/createBorderCss"));
var _grid = require("@rebass/grid");
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _isDate = _interopRequireDefault(require("../../../utils/isDate"));
var _Dropdown = require("../Dropdown");
var _StyledCaret = _interopRequireDefault(require("../StyledCaret"));
var _Context = require("./Context");
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
const _excluded = ["label", "children"];
var __jsx = _react.default.createElement;
const SearchbarDatepickerInputs = ({
  rounded,
  withVerticalPadding,
  startLabel,
  endLabel
}) => {
  const {
    datepicker: [{
      period,
      open,
      inView
    }, {
      set
    }]
  } = (0, _Context.useDatepickerContext)();
  const {
    formatDate
  } = (0, _reactIntl.useIntl)();
  const start = (0, _isDate.default)(period) ? period : period?.start;
  const onClickInput = focus => {
    set({
      inView: start ? [new Date(start), (0, _addMonths.default)(start, 1)] : inView,
      open: true,
      focus
    });
  };
  const onClickStart = () => onClickInput('start');
  const onClickEnd = () => onClickInput(!(0, _isDate.default)(period) && period?.start ? 'end' : 'start');
  return __jsx(StyledButton, {
    focus: open,
    rounded: rounded
  }, __jsx(Part, {
    withVerticalPadding: withVerticalPadding,
    onClick: onClickStart,
    label: startLabel ?? __jsx(_reactIntl.FormattedMessage, {
      id: "VT/CvC",
      defaultMessage: [{
        "type": 0,
        "value": "Aankomst"
      }]
    })
  }, !(0, _isDate.default)(period) && period?.start ? __jsx("span", null, formatDate(period.start, {
    format: 'DATERANGEPICKER'
  })) : __jsx(_reactIntl.FormattedMessage, {
    id: "ELaDA0",
    defaultMessage: [{
      "type": 0,
      "value": "Kies datum"
    }]
  })), __jsx(Part, {
    withVerticalPadding: withVerticalPadding,
    onClick: onClickEnd,
    label: endLabel ?? __jsx(_reactIntl.FormattedMessage, {
      id: "tMip3S",
      defaultMessage: [{
        "type": 0,
        "value": "Vertrek"
      }]
    })
  }, !(0, _isDate.default)(period) && period?.end ? __jsx("span", null, formatDate(period.end, {
    format: 'DATERANGEPICKER'
  })) : __jsx(_reactIntl.FormattedMessage, {
    id: "ELaDA0",
    defaultMessage: [{
      "type": 0,
      "value": "Kies datum"
    }]
  })));
};
var _default = exports.default = SearchbarDatepickerInputs;
const Part = _ref => {
  let {
      label,
      children
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(StyledPart, (0, _extends2.default)({
    type: "button"
  }, props), __jsx(_grid.Box, null, __jsx(StyledBody, null, label), __jsx(StyledDropdownDescription, {
    variant: "small",
    isPlaceholder: false
  }, children)), __jsx(_StyledCaret.default, null));
};
const StyledPart = _styledComponents.default.button.withConfig({
  displayName: "SearchbarDatepickerInputs__StyledPart",
  componentId: "sc-1vms296-0"
})(["display:flex;flex:1;height:100%;align-items:center;justify-content:space-between;padding:", ";:first-of-type{position:relative;::after{content:'';background-color:", ";position:absolute;width:1px;height:70%;top:calc(50% - 70% / 2);right:-0.2rem;display:block;}}> svg{stroke:", ";}:hover{> svg{stroke:", ";}}"], ({
  theme,
  withVerticalPadding
}) => `${withVerticalPadding ? theme.spacing['30_Small'] : 0} ${theme.spacing['40_Standard']}`, ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.colors.neutral['40'], ({
  theme
}) => theme.colors.neutral['90']);
const StyledButton = _styledComponents.default.div.withConfig({
  displayName: "SearchbarDatepickerInputs__StyledButton",
  componentId: "sc-1vms296-1"
})(["height:100%;background:", ";width:100%;padding:0;display:flex;border-radius:", ";", ";--border-color:", ";:hover{--border-color:", ";}", ";:hover,:focus{box-shadow:0 0 0 1.5px var(--border-color);}transition:box-shadow 0.15s ease-in-out;"], ({
  theme
}) => theme.colors.neutral['0'], ({
  theme,
  rounded
}) => rounded && theme.radius.textInput, (0, _createBorderCss.default)('2px'), ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.colors.neutral['40'], ({
  focus,
  theme
}) => focus && `--border-color: ${(0, _theme.getAssignmentColor)(theme, theme.colorAssignments.input)}`);
const StyledDropdownDescription = (0, _styledComponents.default)(_Dropdown.DropdownDescription).withConfig({
  displayName: "SearchbarDatepickerInputs__StyledDropdownDescription",
  componentId: "sc-1vms296-2"
})(["", ";"], ({
  isPlaceholder,
  theme
}) => isPlaceholder && `color: ${theme.colors.neutral['40']}`);
const StyledBody = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'tiny'
}).withConfig({
  displayName: "SearchbarDatepickerInputs__StyledBody",
  componentId: "sc-1vms296-3"
})(["color:", ";font-weight:300 !important;display:flex;line-height:1.15;white-space:nowrap;"], ({
  theme
}) => theme.colors.neutral['60']);