"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createGetServerSideProps = exports.agendaQuery = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));
var _endOfWeek = _interopRequireDefault(require("date-fns/endOfWeek"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _debounce = require("debounce");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _lodash2 = _interopRequireDefault(require("lodash.omit"));
var _lodash3 = _interopRequireDefault(require("lodash.pick"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntersectionObserver = require("react-intersection-observer");
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _useQueryParams = require("use-query-params");
var _activitySearchParams = _interopRequireWildcard(require("../../constants/activitySearchParams"));
var _parseToDateString = _interopRequireDefault(require("../../constants/parseToDateString"));
var _createApolloClient = require("../../createApolloClient");
var _graphql = require("../../generated/graphql");
var _useComposeFilterCategory = _interopRequireDefault(require("../../hooks/search/useComposeFilterCategory"));
var _useEateriesQuery = _interopRequireDefault(require("../../hooks/useEateriesQuery"));
var _useFiltersActive = _interopRequireDefault(require("../../hooks/useFiltersActive"));
var _useQueryParams2 = _interopRequireDefault(require("../../hooks/useQueryParams"));
var _cssCalcBetweenViewport = _interopRequireDefault(require("../../utils/cssCalcBetweenViewport"));
var _getRelevantTime = _interopRequireDefault(require("../../utils/getRelevantTime"));
var _ActivityHit = _interopRequireDefault(require("../ActivityHit"));
var _ActivityTile = _interopRequireDefault(require("../ActivityTile"));
var _AgendaHeader = _interopRequireWildcard(require("../AgendaHeader"));
var _ContentWrapper = _interopRequireDefault(require("../ContentWrapper"));
var _CompanyTile = _interopRequireDefault(require("../designsystem/CompanyTile"));
var _WideGrid = _interopRequireWildcard(require("../designsystem/WideGrid"));
var _Divider = _interopRequireDefault(require("../Divider"));
var _FilterList = _interopRequireDefault(require("../FilterList"));
var _Formed = _interopRequireDefault(require("../Formed"));
var _Page = _interopRequireDefault(require("../Page"));
var _SearchBottomBar = _interopRequireDefault(require("../SearchBottomBar"));
var _SearchFiltersModal = _interopRequireDefault(require("../SearchFiltersModal"));
var _SearchFilterTags = _interopRequireDefault(require("../SearchFilterTags"));
var _TileCarousel = _interopRequireDefault(require("../TileCarousel"));
var _Companies = require("./Companies");
var _DatepickerField = _interopRequireDefault(require("../DatepickerField"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _SearchbarDatepickerInputs = _interopRequireDefault(require("../designsystem/datepicker/SearchbarDatepickerInputs"));
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _TenantContext = require("../../context/TenantContext");
var _FavoriteIconButton = _interopRequireDefault(require("../FavoriteIconButton"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../../hooks/useGetPageSlugByLocale"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const agendaQuery = exports.agendaQuery = (0, _client.gql)`
    query ActivitySearch(
        $params: ActivitySearchParamsInput!
        $limit: Int
        $offset: Int
        $startDate: String!
        $endDate: String!
    ) {
        activities(params: $params, limit: $limit, offset: $offset) {
            ... on ActivitySearchResult {
                totalHits
                hits {
                    ...ActivityHit
                }
                placeCounts {
                    value
                    hits
                }
                themeCounts {
                    value
                    hits
                }
                targetAudienceCount {
                    value
                    hits
                }
            }
        }
    }

    fragment ActivityHit on Activity {
        id
        name
        slug
        highlighted
        place
        videoUrl
        externalBookingUrl
        bookingType

        themes {
            slug
            name
        }

        minCapacity
        maxCapacity
        timeslots(startDate: $startDate, endDate: $endDate) {
            ...ActivityPlannerTimeslot
        }
        themes {
            slug
        }
        mainImage {
            transform(config: HERO, format: JPEG) {
                ...ListImage
            }
        }
        webpMainImage: mainImage {
            transform(config: HERO, format: WEBP) {
                ...ListImage
            }
        }
        webpListImage: listImage {
            transform(config: HERO, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: HERO, format: JPEG) {
                ...ListImage
            }
        }
    }

    query AgendaFilters {
        activityThemes {
            slug
            name
            position
        }
        places {
            id
            position
            name
        }
    }
`;
const targetAudienceMessages = (0, _reactIntl.defineMessages)({
  ALL_AGES: {
    "id": "hW5eyI",
    "defaultMessage": [{
      "type": 0,
      "value": "Alle leeftijden"
    }]
  },
  ADULTS: {
    "id": "kqs3yQ",
    "defaultMessage": [{
      "type": 0,
      "value": "Volwassenen"
    }]
  },
  CHILDREN: {
    "id": "6ju3dZ",
    "defaultMessage": [{
      "type": 0,
      "value": "Kinderen"
    }]
  },
  WHEELCHAIR_ACCESSIBLE: {
    "id": "e+ozX6",
    "defaultMessage": [{
      "type": 0,
      "value": "Rolstoeltoegankelijk"
    }]
  },
  PETS: {
    "id": "1E1B4r",
    "defaultMessage": [{
      "type": 0,
      "value": "Met huisdier"
    }]
  }
});
const useImageSizes = () => {
  const {
    mediaQueries
  } = (0, _useThemeContext.useThemeContext)();
  return `(min-width: ${mediaQueries.xxl}) 25vw, (min-width: ${mediaQueries.xl}) 25vw, (min-width: ${mediaQueries.s}) 25vw, 100vw`;
};
const determinePeriod = (date, view) => {
  switch (view) {
    case 'month':
      {
        const startDate = (0, _startOfMonth.default)(new Date(date));
        const endDate = (0, _endOfMonth.default)(startDate);
        return [(0, _parseToDateString.default)(startDate), (0, _parseToDateString.default)(endDate)];
      }
    case 'week':
      {
        const startDate = new Date(date);
        const endDate = (0, _endOfWeek.default)(startDate, _AgendaHeader.weekOpts);
        return [(0, _parseToDateString.default)(startDate), (0, _parseToDateString.default)(endDate)];
      }
    default:
      return [date, date];
  }
};
const filterKeys = ['themeSlugs', 'placeId', 'targetAudiences'];

// NOTE: these are hopefully temporary hardcoded
const eaterySubCategories = ['paviljoens', 'cafes', 'restaurants', 'catering', 'snackbar', 'discotheken', 'vis-cafetaria'];
const Agenda = ({
  amountPerPage,
  bakedInParams,
  startParams,
  skipHighlights = false,
  skipCompanies = false,
  skipEateries = false,
  ssr = false,
  hideTitle = false
}) => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const intl = (0, _reactIntl.useIntl)();
  const sizes = useImageSizes();
  const companySizes = (0, _Companies.useCompanyImageSizes)();
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const shouldAutoSave = deviceSize !== 'mobile';
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const companyPageSlug = getPageSlugByLocale('COMPANY');
  const filterModal = (0, _useSesame.default)();
  const today = (0, _react.useMemo)(() => new Date(), []);
  /** parse params */
  const [params, setParams] = (0, _useQueryParams2.default)(_activitySearchParams.default);
  const [filtersActive, removeActiveFilters] = (0, _useFiltersActive.default)(_activitySearchParams.activitySearchFilterParams);
  const {
    placeId,
    startDate = (0, _parseToDateString.default)(today),
    endDate = (0, _parseToDateString.default)((0, _addDays.default)(today, 7)),
    targetAudiences = [],
    themeSlugs = [],
    view = 'day',
    companyId
  } = _objectSpread(_objectSpread(_objectSpread({}, startParams), params), bakedInParams);
  /** fetch filters */
  const filtersQuery = (0, _graphql.useAgendaFiltersQuery)({
    ssr
  });
  const onViewChange = (0, _react.useCallback)(newView => {
    if (newView === 'day') {
      const [periodStartDate, periodEndDate] = determinePeriod((0, _parseToDateString.default)(today), newView);
      setParams({
        startDate: periodStartDate,
        endDate: periodEndDate,
        view: newView
      });
    } else {
      const [periodStartDate, periodEndDate] = determinePeriod(startDate, newView);
      setParams({
        startDate: periodStartDate,
        endDate: periodEndDate,
        view: newView
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [setParams]);
  const initialStartDate = startDate.length > 0 ? startDate : (0, _parseToDateString.default)(today);
  const initialEndDate = endDate.length > 0 ? endDate : (0, _parseToDateString.default)((0, _addDays.default)(today, 7));
  /** process current filters */
  const paramsInput = {
    startDate: initialStartDate,
    endDate: initialEndDate,
    placeId,
    targetAudiences: targetAudiences,
    themeSlugs,
    companyId
  };

  /** fetch activities */
  const {
    data,
    loading,
    fetchMore,
    variables,
    networkStatus
  } = (0, _graphql.useActivitySearchQuery)({
    variables: {
      limit: amountPerPage,
      params: paramsInput,
      startDate: initialStartDate,
      endDate: initialEndDate
    },
    notifyOnNetworkStatusChange: true,
    ssr
  });
  const highlightedQuery = (0, _graphql.useActivitySearchQuery)({
    variables: {
      limit: 4,
      params: _objectSpread(_objectSpread({}, paramsInput), {}, {
        highlighted: true
      }),
      startDate: initialStartDate,
      endDate: initialEndDate
    },
    notifyOnNetworkStatusChange: true,
    ssr,
    skip: skipHighlights
  });
  const canLoadMoreActivities = data && data.activities.totalHits > data.activities.hits.length;

  /** as eateries are below the fold, we'll query 'm lazily */
  const [eateriesRef, eateriesInView] = (0, _reactIntersectionObserver.useInView)({
    triggerOnce: true,
    rootMargin: '500px'
  });
  const eateriesQuery = (0, _useEateriesQuery.default)({
    variables: {
      limit: 8,
      params: {
        placeId,
        showInCalendar: true,
        startDate: initialStartDate,
        endDate: initialEndDate
      }
    },
    skip: !eateriesInView || skipEateries
  });
  const companiesQuery = (0, _graphql.useCompanySearchQuery)({
    variables: {
      limit: 8,
      params: {
        placeId,
        showInCalendar: true,
        startDate: initialStartDate,
        endDate: initialEndDate,
        targetAudiences: targetAudiences,
        themeSlugs
      }
    },
    ssr,
    skip: skipCompanies
  });

  /** handle formik inputs */
  const handleSubmit = (0, _react.useCallback)(values => {
    // by changing the params, apollo will refetch the query automagically
    setParams({
      startDate: values.startDate,
      endDate: values.endDate,
      view: values.view,
      placeId: values.placeId?.[0],
      targetAudiences: values.targetAudiences,
      themeSlugs: values.themeSlugs
    }, 'replaceIn');
  }, [setParams]);
  // TODO use useMemo in order te create the function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAutoSave = (0, _react.useCallback)((0, _debounce.debounce)(handleSubmit), [handleSubmit]);

  /** parse activities results */
  const {
    totalHits = 0
  } = data?.activities ?? {};
  const activities = data?.activities.hits;

  /** parse companies results */
  const filteredCompanies = (0, _react.useMemo)(() => companiesQuery.data?.companies.hits.filter(company => company.categories.every(category => !eaterySubCategories.includes(category.slug))), [companiesQuery.data]);
  const activityThemeCategory = (0, _react.useMemo)(() => (0, _useComposeFilterCategory.default)({
    categoryHandle: 'themeSlugs',
    categoryLabel: intl.formatMessage({
      "id": "LrOcWc",
      "defaultMessage": [{
        "type": 0,
        "value": "Thema"
      }]
    }),
    filterProperties: [...(filtersQuery.data?.activityThemes ?? [])].sort((activityThemeA, activityThemeB) => activityThemeA.position - activityThemeB.position),
    filterResolver: ({
      slug,
      name
    }) => ({
      handle: slug,
      label: name ?? ''
    }),
    filterCounts: data?.activities.themeCounts ?? [],
    filterCountKey: 'value'
  }), [data, filtersQuery.data, intl]);
  const placesFilterCategory = (0, _react.useMemo)(() => (0, _useComposeFilterCategory.default)({
    categoryHandle: 'placeId',
    categoryLabel: intl.formatMessage({
      "id": "etjfNt",
      "defaultMessage": [{
        "type": 0,
        "value": "Plaats"
      }]
    }),
    filterProperties: filtersQuery.data?.places ?? [],
    filterResolver: ({
      id,
      name
    }) => ({
      handle: id,
      label: name
    }),
    filterCounts: data?.activities.placeCounts ?? [],
    filterCountKey: 'value'
  }), [data, filtersQuery.data, intl]);
  const targetAudienceCategory = (0, _react.useMemo)(() => {
    const targets = Object.entries(targetAudienceMessages).map(([key, value]) => ({
      label: intl.formatMessage(value),
      handle: key
    }));
    return (0, _useComposeFilterCategory.default)({
      categoryHandle: 'targetAudiences',
      categoryLabel: intl.formatMessage({
        "id": "T0Engk",
        "defaultMessage": [{
          "type": 0,
          "value": "Doelgroep"
        }]
      }),
      filterProperties: targets,
      filterCounts: data?.activities.targetAudienceCount ?? [],
      filterCountKey: 'value'
    });
  }, [data, intl]);
  const filterCategories = [activityThemeCategory, placesFilterCategory, targetAudienceCategory];

  /** filters changed */
  const activityFilters = (0, _react.useMemo)(() => (0, _lodash3.default)(params, Object.keys(_activitySearchParams.activitySearchFilterParams)), [params]);
  const bakedActivityFilters = (0, _react.useMemo)(() => (0, _lodash3.default)(bakedInParams, Object.keys(_activitySearchParams.activitySearchFilterParams)), [bakedInParams]);
  const filtersUnchanged = (0, _react.useMemo)(() => Object.values(activityFilters).length === 0 || Object.values(bakedActivityFilters).length > 0 && (0, _lodash.default)(activityFilters, bakedActivityFilters), [activityFilters, bakedActivityFilters]);
  const resetDate = (0, _react.useCallback)(() => {
    onViewChange('day');
  }, [onViewChange]);
  return __jsx(_Page.default, {
    id: "search-page"
  }, __jsx(_Formed.default, {
    skipPrompt: true,
    initialValues: {
      startDate: initialStartDate,
      endDate: initialEndDate,
      view: view,
      placeId: placeId ? [placeId] : [],
      themeSlugs,
      targetAudiences: targetAudiences
    },
    handleAutoSave: (values, helpers) => {
      helpers.setSubmitting(true);
      handleAutoSave(values);
    },
    handleSubmit: handleSubmit,
    enableReinitialize: true
  }, ({
    isSubmitting
  }) => {
    const isLoading = loading && networkStatus !== _client.NetworkStatus.fetchMore || isSubmitting;
    const isLoadingHighlighted = highlightedQuery.networkStatus === _client.NetworkStatus.loading || isSubmitting;
    return __jsx(StyledContentWrapper, null, __jsx(_AgendaHeader.default, {
      onViewChange: onViewChange,
      resetDate: resetDate
    }), __jsx(_grid.Flex, {
      flexDirection: ['column', null, null, 'row']
    }, __jsx(LeftBar, {
      className: "gt-m"
    }, __jsx(SectionHeader, {
      hideTitle: hideTitle
    }, __jsx(_Title.default, {
      style: {
        fontWeight: 600
      }
    }, totalHits === 1 ? __jsx(_react.default.Fragment, null, __jsx("span", null, totalHits), ' ', __jsx(_reactIntl.FormattedMessage, {
      id: "2PjVlv",
      defaultMessage: [{
        "type": 0,
        "value": "activiteit"
      }]
    })) : __jsx(_react.default.Fragment, null, __jsx("span", null, totalHits), ' ', __jsx(_reactIntl.FormattedMessage, {
      id: "8Tht4S",
      defaultMessage: [{
        "type": 0,
        "value": "activiteiten"
      }]
    })))), __jsx(FilterContainer, {
      className: "gt-m"
    }, __jsx(_DatepickerField.default, {
      optionalSingleDate: true,
      startName: "startDate",
      endName: "endDate",
      align: "left"
    }, __jsx(_SearchbarDatepickerInputs.default, {
      startLabel: __jsx(_reactIntl.FormattedMessage, {
        id: "5ZBtyk",
        defaultMessage: [{
          "type": 0,
          "value": "Van"
        }]
      }),
      endLabel: __jsx(_reactIntl.FormattedMessage, {
        id: "SAN+g4",
        defaultMessage: [{
          "type": 0,
          "value": "Tot"
        }]
      }),
      withVerticalPadding: true,
      rounded: true
    }))), __jsx(_grid.Box, {
      my: 4
    }, __jsx(_Button.default, {
      variant: "outline",
      size: "small",
      onClick: () => resetDate()
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "jm/spn",
      defaultMessage: [{
        "type": 0,
        "value": "Reset"
      }]
    }))), __jsx(_Divider.default, null), filtersQuery.loading && __jsx(_FilterList.default, {
      mt: 6,
      loading: true
    }), !filtersQuery.loading && __jsx(_react.default.Fragment, null, __jsx(_SearchFilterTags.default, {
      shouldAutoSave: shouldAutoSave,
      categories: filterCategories
    }), __jsx(_FilterList.default, {
      categories: filterCategories
    }))), __jsx(_SearchBottomBar.default, {
      allowedActions: ['filters'],
      filtersActive: filtersActive,
      onFiltersClick: filterModal.onOpen
    }), __jsx(_SearchFiltersModal.default, {
      dirtyCheckKeys: filterKeys,
      filtersActive: filtersActive,
      onClose: filterModal.onClose,
      open: filterModal.open,
      removeActiveFilters: removeActiveFilters
    }, __jsx(_FilterList.default, {
      categories: filterCategories
    })), __jsx(ListingSection, null, !isLoading && totalHits === 0 && __jsx("section", null, __jsx(SectionHeader, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
      id: "TtZ0Yn",
      defaultMessage: [{
        "type": 0,
        "value": "Geen activiteiten gevonden"
      }]
    }))), __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
      id: "oszQh+",
      defaultMessage: [{
        "type": 0,
        "value": "Op deze dag zijn er geen activiteiten met deze filters."
      }]
    })), !filtersUnchanged && __jsx(_react.default.Fragment, null, __jsx("br", null), __jsx(_Button.default, {
      style: {
        alignSelf: 'flex-start'
      },
      variant: "outline",
      onClick: removeActiveFilters,
      type: "button"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "+PgRNi",
      defaultMessage: [{
        "type": 0,
        "value": "Filters verwijderen"
      }]
    })))), isLoadingHighlighted && __jsx("section", null, __jsx(SectionHeader, null, __jsx(_reactLoadingSkeleton.default, {
      width: "20rem",
      height: "2rem"
    })), __jsx(_WideGrid.default, {
      widths: [100, 50, 50]
    }, [...new Array(2)].map((_, i) => __jsx(_WideGrid.Cell, {
      key: i
    }, __jsx(_ActivityTile.default, {
      loading: true,
      large: true
    }))))), !isLoadingHighlighted && highlightedQuery.data?.activities.hits && highlightedQuery.data?.activities.hits.length > 0 && __jsx("section", null, !hideTitle && __jsx(SectionHeader, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
      id: "LvSlgH",
      defaultMessage: [{
        "type": 0,
        "value": "Uitgelicht"
      }]
    }))), __jsx(_TileCarousel.default, {
      large: true,
      tiles: highlightedQuery.data?.activities.hits?.map((a, i) => __jsx(_ActivityHit.default, {
        key: i,
        hit: a,
        dontPassDate: view !== 'day',
        sizes: sizes,
        large: true
      })),
      canLoadMore: highlightedQuery.data.activities.hits.length < highlightedQuery.data.activities.totalHits,
      onLoadMore: () => highlightedQuery.fetchMore({
        variables: {
          offset: highlightedQuery.data?.activities.hits.length
        },
        updateQuery: (prev, next) => _objectSpread(_objectSpread({}, prev), {}, {
          activities: _objectSpread(_objectSpread({}, prev.activities), {}, {
            hits: prev.activities.hits.concat(next.fetchMoreResult?.activities.hits ?? [])
          })
        })
      }),
      loadingIndicator: __jsx(_ActivityTile.default, {
        large: true,
        loading: true
      })
    })), companiesQuery.data && filteredCompanies && filteredCompanies.length > 0 && __jsx("section", null, !hideTitle && __jsx(SectionHeader, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
      id: "f90u+i",
      defaultMessage: [{
        "type": 0,
        "value": "Breng een bezoek aan"
      }]
    }))), __jsx(_TileCarousel.default, {
      tiles: filteredCompanies.map((company, i) => __jsx(_CompanyTile.default, {
        key: i,
        name: company.name,
        place: company.place,
        videoUrl: company.videoUrl,
        time: view === 'day' ? (0, _getRelevantTime.default)(intl, company.normalOperatingHours, company.specialOperatingHours, new Date(startDate)) : undefined,
        icon: brandConfig.enableFavorites && __jsx(_FavoriteIconButton.default, {
          type: "company",
          id: company.id
        }),
        linkProps: {
          href: {
            pathname: `/${companyPageSlug}/${company.slug}`
          },
          locale: intl.locale
        },
        sources: [{
          srcSet: company.webpListImage?.transform?.srcSet ?? company.webpMainImage?.transform?.srcSet,
          type: 'image/webp',
          sizes: companySizes
        }, {
          srcSet: company.listImage?.transform?.srcSet ?? company.mainImage?.transform?.srcSet,
          type: 'image/jpeg',
          sizes: companySizes
        }],
        fallback: {
          src: company.listImage?.transform?.src ?? company.mainImage?.transform?.src
        }
      })),
      canLoadMore: companiesQuery.data.companies.hits.length < companiesQuery.data.companies.totalHits,
      onLoadMore: () => companiesQuery.fetchMore({
        variables: {
          offset: companiesQuery.data?.companies.hits.length
        },
        updateQuery: (prev, next) => _objectSpread(_objectSpread({}, prev), {}, {
          companies: _objectSpread(_objectSpread({}, prev.companies), {}, {
            hits: prev.companies.hits.concat(next.fetchMoreResult?.companies.hits ?? [])
          })
        })
      }),
      loadingIndicator: __jsx(_CompanyTile.default, {
        loading: true
      })
    })), isLoading && __jsx("section", null, __jsx(SectionHeader, null, __jsx(_reactLoadingSkeleton.default, {
      width: "20rem",
      height: "2rem"
    })), __jsx(_WideGrid.default, {
      widths: [100, 50, 100 / 3]
    }, [...new Array(6)].map((x, i) => __jsx(_WideGrid.Cell, {
      key: i
    }, __jsx(_ActivityTile.default, {
      loading: true
    }))))), activities && activities.length > 0 && __jsx("section", null, !hideTitle && __jsx(SectionHeader, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
      id: "sW1X4q",
      defaultMessage: [{
        "type": 0,
        "value": "Activiteiten"
      }]
    }))), __jsx(_WideGrid.default, {
      widths: [100, 50, 100 / 3]
    }, activities.map((a, i) => __jsx(_WideGrid.Cell, {
      key: i
    }, __jsx(_ActivityHit.default, {
      hit: a,
      dontPassDate: view !== 'day',
      sizes: sizes
    }), i !== activities.length - 1 && __jsx(_Divider.default, {
      mt: "2.4rem",
      mb: 0,
      className: "lt-s"
    })))), canLoadMoreActivities && __jsx(_grid.Flex, {
      width: 1,
      justifyContent: "center",
      my: 7
    }, __jsx(_Button.default, {
      submitting: networkStatus === _client.NetworkStatus.fetchMore,
      variant: "outline",
      onClick: () => fetchMore({
        variables: _objectSpread(_objectSpread({}, variables), {}, {
          offset: data?.activities.hits.length ?? 0
        }),
        updateQuery: (prev, {
          fetchMoreResult
        }) => {
          if (!prev.activities || !fetchMoreResult?.activities) {
            return prev;
          }
          return _objectSpread(_objectSpread({}, prev), {}, {
            activities: _objectSpread(_objectSpread({}, prev.activities), {}, {
              hits: [...prev.activities.hits, ...fetchMoreResult.activities.hits]
            })
          });
        }
      })
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "iQsptE",
      defaultMessage: [{
        "type": 0,
        "value": "Meer laden..."
      }]
    })))), __jsx("section", {
      ref: eateriesRef
    }, eateriesQuery.networkStatus === _client.NetworkStatus.loading && __jsx(_react.default.Fragment, null, __jsx(SectionHeader, null, __jsx(_reactLoadingSkeleton.default, {
      width: "20rem",
      height: "2rem"
    })), __jsx(_TileCarousel.default, {
      tiles: [...new Array(3)].map((_, i) => __jsx(_ActivityTile.default, {
        loading: true,
        key: i
      }))
    })), eateriesQuery.data?.companies && eateriesQuery.data.companies.hits.length > 0 && __jsx(_react.default.Fragment, null, !hideTitle && __jsx(SectionHeader, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
      id: "7xVx9U",
      defaultMessage: [{
        "type": 0,
        "value": "Eten en drinken"
      }]
    }))), __jsx(_TileCarousel.default, {
      canLoadMore: eateriesQuery.data.companies.hits.length < eateriesQuery.data.companies.totalHits,
      onLoadMore: () => eateriesQuery.fetchMore({
        variables: {
          offset: eateriesQuery.data?.companies.hits.length
        },
        updateQuery: (prev, next) => _objectSpread(_objectSpread({}, prev), {}, {
          companies: _objectSpread(_objectSpread({}, prev.companies), {}, {
            hits: prev.companies.hits.concat(next.fetchMoreResult?.companies.hits ?? [])
          })
        })
      }),
      loadingIndicator: __jsx(_CompanyTile.default, {
        loading: true
      }),
      tiles: eateriesQuery.data.companies.hits.map((company, i) => __jsx(_CompanyTile.default, {
        key: i,
        name: company.name,
        place: company.place,
        icon: brandConfig.enableFavorites && __jsx(_FavoriteIconButton.default, {
          type: "company",
          id: company.id
        }),
        time: view === 'day' || startDate === endDate ? (0, _getRelevantTime.default)(intl, company.normalOperatingHours, company.specialOperatingHours, new Date(startDate)) : undefined,
        linkProps: {
          href: {
            pathname: `/${companyPageSlug}/${company.slug}`
          },
          locale: intl.locale
        },
        sources: [{
          srcSet: company.webpListImage?.transform?.srcSet ?? company.webpMainImage?.transform?.srcSet,
          type: 'image/webp',
          sizes: companySizes
        }, {
          srcSet: company.listImage?.transform?.srcSet ?? company.mainImage?.transform?.srcSet,
          type: 'image/jpeg',
          sizes: companySizes
        }],
        fallback: {
          src: company.listImage?.transform?.src ?? company.mainImage?.transform?.src
        }
      }))
    }))))));
  }));
};
var _default = exports.default = Agenda;
const createGetServerSideProps = ({
  amountsPerPage,
  startParams = {},
  bakedInParams = {},
  client: existingClient
}) => async context => {
  const query = 'query' in context ? context.query : context.params;
  const today = new Date();
  const queryParams = _objectSpread(_objectSpread(_objectSpread({}, startParams), (0, _useQueryParams.decodeQueryParams)(_activitySearchParams.default, (0, _lodash2.default)(query, ['locale', ...Object.keys(bakedInParams)]))), bakedInParams);
  const {
    startDate = (0, _parseToDateString.default)(today),
    endDate = (0, _parseToDateString.default)(today),
    targetAudiences,
    themeSlugs,
    placeId
  } = queryParams;
  const paramsInput = {
    startDate,
    endDate,
    targetAudiences: targetAudiences,
    themeSlugs,
    placeId
  };
  const client = existingClient ?? (0, _createApolloClient.initApolloClient)(context.locale);
  await client.query({
    query: _graphql.ActivitySearchDocument,
    variables: {
      // some protection as an offset < 0 will let the BE explode 🧨
      offset: 0,
      limit: amountsPerPage,
      params: paramsInput,
      startDate,
      endDate
    }
  });
  return (0, _createApolloClient.addApolloState)(client, {
    props: {
      bakedInParams,
      startParams
    }
  });
};
exports.createGetServerSideProps = createGetServerSideProps;
const StyledContentWrapper = (0, _styledComponents.default)(_ContentWrapper.default).withConfig({
  displayName: "Agenda__StyledContentWrapper",
  componentId: "xz5r09-0"
})(["padding-bottom:8rem;"]);
const SectionHeader = (0, _styledComponents.default)(_AgendaHeader.Header).withConfig({
  displayName: "Agenda__SectionHeader",
  componentId: "xz5r09-1"
})(["margin:", ";@media screen and (min-width:", "){margin:", ";}"], ({
  hideTitle
}) => hideTitle ? '0 0 4rem 0' : '4rem 0', ({
  theme
}) => theme.mediaQueries.xs, ({
  hideTitle
}) => hideTitle ? '0 0 1.6rem' : '4rem 0 1.6rem');
const LeftBar = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Agenda__LeftBar",
  componentId: "xz5r09-2"
})(["flex-shrink:0;@media screen and (min-width:", "){width:32.8rem;padding-right:", ";", "{margin-bottom:2.4rem;}}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['60_Large'], SectionHeader);
const FilterContainer = _styledComponents.default.div.withConfig({
  displayName: "Agenda__FilterContainer",
  componentId: "xz5r09-3"
})(["display:flex;flex-flow:column;> span + span{margin-top:1rem;}"]);
const ListingSection = _styledComponents.default.section.withConfig({
  displayName: "Agenda__ListingSection",
  componentId: "xz5r09-4"
})(["width:100%;& > * + *{margin-top:", ";}"], (0, _cssCalcBetweenViewport.default)(24, 56));