import 'url-polyfill';
import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import getCurrentBaseUrl from '../utils/getCurrentBaseUrl';
import Head from 'next/head';

interface Props {
    settingsMetaDescription: string;
    settingsSeoTitle: string;
    alternates: Maybe<Array<Maybe<{ url?: Maybe<string> }>>>;
    image?: string;
}

const Seo: FC<React.PropsWithChildren<Props>> = ({ settingsMetaDescription, settingsSeoTitle, image, alternates }) => {
    const { asPath, locale } = useRouter();
    const title = settingsSeoTitle.concat(' | VVV Texel');
    return (
        <Head>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={settingsMetaDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`${getCurrentBaseUrl(locale)}${asPath}`} />
            <meta property="og:title" content={settingsSeoTitle.concat(' | VVV Texel')} />
            <meta property="og:description" content={settingsMetaDescription} />
            <link rel="canonical" href={`${getCurrentBaseUrl(locale)}${asPath}`} />
            {image && <meta property="og:image" content={image} />}
            {image && <meta property="og:image:secure" content={image} />}
            {alternates?.map(alternate => {
                const { pathname: path } = alternate?.url ? new URL(alternate.url) : { pathname: '' };
                const lang = path.split('/')[1];
                return alternate?.url && <link key={lang} rel="alternate" hrefLang={lang} href={alternate.url} />;
            })}
        </Head>
    );
};

export default Seo;
