"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RoundButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/RoundButton"));
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _debounce = require("debounce");
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _WideGrid = require("./designsystem/WideGrid");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const findClosestOffset = (scrollLeft, tiles) => {
  let lastClosestIndex;
  let lastClosest = Number.MAX_SAFE_INTEGER;
  tiles.forEach((t, i) => {
    const o = Math.abs(t.offsetLeft + parseFloat(getComputedStyle(t).paddingLeft) - scrollLeft);
    if (o < lastClosest) {
      lastClosestIndex = i;
      lastClosest = o;
    }
  });
  return lastClosestIndex !== undefined ? tiles[lastClosestIndex].offsetLeft + parseFloat(getComputedStyle(tiles[lastClosestIndex]).paddingLeft) : undefined;
};
const TileCarousel = ({
  tiles,
  onLoadMore,
  loadingIndicator,
  canLoadMore,
  large
}) => {
  const shouldRenderLoadingIndicator = !!loadingIndicator && canLoadMore;
  const device = (0, _devicesize.useDeviceSize)();
  const container = (0, _react.useRef)(null);
  const onPrev = (0, _react.useCallback)(() => {
    const c = container.current;
    if (c) {
      c.scrollTo({
        left: findClosestOffset(c.scrollLeft - c.clientWidth, c.querySelectorAll('[data-cell]')),
        behavior: 'smooth'
      });
    }
  }, []);
  const onNext = (0, _react.useCallback)(() => {
    const c = container.current;
    if (c) {
      c.scrollTo({
        left: findClosestOffset(c.scrollLeft + c.clientWidth, c.querySelectorAll('[data-cell]')),
        behavior: 'smooth'
      });
    }
  }, []);
  const {
    0: prevEnabled,
    1: setPrevEnabled
  } = (0, _react.useState)(false);
  const {
    0: nextEnabled,
    1: setNextEnabled
  } = (0, _react.useState)(false);
  const {
    0: loading,
    1: setLoading
  } = (0, _react.useState)(false);
  const performOnLoadMore = (0, _react.useCallback)(async () => {
    if (!onLoadMore || loading || !canLoadMore) {
      return;
    }
    setLoading(true);
    await onLoadMore();
    setLoading(false);
    setNextEnabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoadMore, loading]);
  (0, _react.useEffect)(() => {
    const el = container.current;
    if (!el) {
      return undefined;
    }
    const handler = (0, _debounce.debounce)(() => {
      const elScrollPos = Math.round(el.scrollLeft + el.clientWidth);
      const reachedEnd = elScrollPos === el.scrollWidth;
      if (elScrollPos >= el.scrollWidth - (el.clientWidth + 100)) {
        performOnLoadMore();
      }
      setNextEnabled(!reachedEnd);
      setPrevEnabled(el.scrollLeft > 0);
    });
    handler();
    el.addEventListener('scroll', handler);
    return () => el.removeEventListener('scroll', handler);
  }, [performOnLoadMore]);
  return __jsx(Container, null, __jsx(NavButtons, null, __jsx(_RoundButton.default, {
    disabled: !prevEnabled,
    variant: "outline",
    onClick: onPrev,
    size: device === 'mobile' ? 'small' : 'medium'
  }, __jsx(_ArrowLeft.default, null)), __jsx(_RoundButton.default, {
    disabled: !nextEnabled,
    variant: "outline",
    onClick: onNext,
    size: device === 'mobile' ? 'small' : 'medium'
  }, __jsx(_ArrowRight.default, null))), __jsx(Carousel, {
    ref: container
  }, __jsx(_WideGrid.CarouselGrid, {
    length: tiles.length + (shouldRenderLoadingIndicator ? 1 : 0),
    widths: [100, 50, large ? 50 : 100 / 3]
  }, tiles.map((tile, i) => __jsx(_WideGrid.Cell, {
    "data-cell": true,
    key: i
  }, tile)), shouldRenderLoadingIndicator && __jsx(_WideGrid.Cell, {
    style: {
      scrollSnapAlign: 'none'
    },
    "data-cell": true
  }, loadingIndicator))));
};
const Container = _styledComponents.default.div.withConfig({
  displayName: "TileCarousel__Container",
  componentId: "iv0tmy-0"
})(["position:relative;"]);
const Carousel = _styledComponents.default.div.withConfig({
  displayName: "TileCarousel__Carousel",
  componentId: "iv0tmy-1"
})(["overflow-x:scroll;overflow-y:hidden;scroll-snap-type:x mandatory;scrollbar-width:none;-ms-overflow-style:none;overscroll-behavior-x:none;will-change:transform;-webkit-overflow-scrolling:touch;&::-webkit-scrollbar{display:none;}"]);
const NavButtons = _styledComponents.default.div.withConfig({
  displayName: "TileCarousel__NavButtons",
  componentId: "iv0tmy-2"
})(["position:absolute;top:-6.8rem;right:0;& > * + *{margin-left:0.8rem;}@media screen and (min-width:", "){top:-6.8rem;}"], ({
  theme
}) => theme.mediaQueries.xs);
var _default = exports.default = TileCarousel;